import React, { useContext, useEffect } from "react";
import { KfHeroUtility } from "@klickinc/kf-react-components";
import AppContext from "@src/context";
import { Link } from "gatsby"
// Utility imports
import Seo from "@components/utility/SEO";
import "./styles.scss"

const NotFound = () => {
	const ctx = useContext(AppContext);

	useEffect(() => {
		ctx.setPageClass('page--404');
	},[]);

	return (<>
		<section>
			<div className="container container--inner">
				<KfHeroUtility addedClass="heading-hero-blue heading">
					<span>404</span>
					<br />
					Page not found
				</KfHeroUtility>
				<p className="pb-12">
					The page you were looking for could not be found. It might
					have been removed, renamed, or did not exist in the first
					place.
				</p>
				<Link to='/' className='link-btn link-btn--blue sm:max-w-[18.75rem] md:max-w-[16rem] link-hero-dark-blue'>Return Home</Link>
			</div>
		<div className="bg--blue"></div>
		</section>
	</>);
}

export default NotFound;


export function Head() {
    return (
		<Seo title="Page Not Found - EMFLAZA® (deflazacort)"/>
    );
};

